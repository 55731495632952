import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, Typography, DialogTitle } from '@material-ui/core';

class Instructions extends Component {
	
	static propTypes = {
		open: PropTypes.bool.isRequired,
		closeInstructionsDialog: PropTypes.func.isRequired
	};

	render() {
		return (
			<div>
				<Dialog open={this.props.open} onClose={this.props.closeInstructionsDialog} scroll="paper">
					<DialogTitle>Instructions</DialogTitle>
					<DialogContent>
						<Typography variant="subtitle1">Add Archer</Typography>
						<Typography paragraph>Click on an empty card and enter the archer&apos;s name.</Typography>
						<Typography variant="subtitle1">Swap Archers</Typography>
						<Typography paragraph>Enter Swap Mode by clicking the icon in the title bar, select the two relavent archers to swap by clicking them and then commit the swap by selecting the confirm button in the title bar. Exit Swap Mode by clicking the exit icon in the title bar.</Typography>
						<Typography variant="subtitle1">View Scores</Typography>
						<Typography paragraph>Click on an archer&apos;s card to view their scores in reverse-chronological order.</Typography>
						<Typography variant="subtitle1">Add Score</Typography>
						<Typography paragraph>Click on an archer&apos;s card, then click the &lsquo;Add Scores&rsquo; to enter the relavent scores in the pop-up dialog box.</Typography>
						<Typography variant="subtitle1">Edit Score</Typography>
						<Typography paragraph>Click on an archer&apos;s card and then click on the relavent score to edit by re-entering the edited score in the pop-up dialog box.</Typography>
						<Typography variant="subtitle1">Delete Data</Typography>
						<Typography paragraph>Select the delete data option in the drop down menu in the title bar and confirm the prompt dialog. This will delete all data stored in the app. Only use this feature to reset the app for the next year.</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.closeInstructionsDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default Instructions;
