import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import Archer from './Archer';
import './Detail.scss';

class Detail extends Component {

	static propTypes = {
		row: PropTypes.number.isRequired,
		archers: PropTypes.array.isRequired,
		name: PropTypes.string.isRequired,
		openListDialog: PropTypes.func.isRequired,
		openAddDialog: PropTypes.func.isRequired,
		swapMode: PropTypes.bool.isRequired,
		selectedSwaps: PropTypes.array.isRequired,
		swapCheckboxChange: PropTypes.func.isRequired
	};

	render() {
		const indexes = [
			(this.props.row * 3) - 3,
			(this.props.row * 3) - 2,
			(this.props.row * 3) - 1,
		];

		const archers = [
			this.props.archers[indexes[0]] || {name: null, scores: null},
			this.props.archers[indexes[1]] || {name: null, scores: null},
			this.props.archers[indexes[2]] || {name: null, scores: null}
		];

		const ids = [
			this.props.archers[indexes[0]] ? indexes[0] : null,
			this.props.archers[indexes[1]] ? indexes[1] : null,
			this.props.archers[indexes[2]] ? indexes[2] : null
		];

		const names = [
			archers[0].name || "Name",
			archers[1].name || "Name",
			archers[2].name || "Name"
		];

		const scores = [
			archers[0].scores || [0],
			archers[1].scores || [0],
			archers[2].scores || [0]
		];

		const averages = [
			Math.round(scores[0].reduce((a,b) => Number(a) + Number(b), 0) / scores[0].length),
			Math.round(scores[1].reduce((a,b) => Number(a) + Number(b), 0) / scores[1].length),
			Math.round(scores[2].reduce((a,b) => Number(a) + Number(b), 0) / scores[2].length)
		];

		const lasts = [
			scores[0][scores[0].length - 1],
			scores[1][scores[1].length - 1],
			scores[2][scores[2].length - 1]
		];

		const relatives = [
			Math.sign(Math.round(scores[0].slice(-3).reduce((a,b) => Number(a) + Number(b), 0) / scores[0].slice(-3).length) - averages[0]),
			Math.sign(Math.round(scores[1].slice(-3).reduce((a,b) => Number(a) + Number(b), 0) / scores[1].slice(-3).length) - averages[1]),
			Math.sign(Math.round(scores[2].slice(-3).reduce((a,b) => Number(a) + Number(b), 0) / scores[2].slice(-3).length) - averages[2])
		];

		const total = lasts.reduce((a,b) => Number(a) + Number(b), 0);

		return (
			<div className="Detail">
				<Typography gutterBottom align="left" display="inline" variant="body2" className="detail-name">{this.props.name}</Typography>
				<Typography gutterBottom align="right" display="inline" variant="body2" className="detail-score">Total: {total}</Typography>
				<Grid container justify="center">
					<Archer id={(ids[0])} index={(indexes[0])} name={(names[0])} average={Number(averages[0])} last={Number(lasts[0])} relative={relatives[0]} openListDialog={this.props.openListDialog} openAddDialog={this.props.openAddDialog} swapMode={this.props.swapMode} selectedSwaps={this.props.selectedSwaps} swapCheckboxChange={this.props.swapCheckboxChange} />
					<Archer id={(ids[1])} index={(indexes[1])} name={(names[1])} average={Number(averages[1])} last={Number(lasts[1])} relative={relatives[1]} openListDialog={this.props.openListDialog} openAddDialog={this.props.openAddDialog} swapMode={this.props.swapMode} selectedSwaps={this.props.selectedSwaps} swapCheckboxChange={this.props.swapCheckboxChange} />
					<Archer id={(ids[2])} index={(indexes[2])} name={(names[2])} average={Number(averages[2])} last={Number(lasts[2])} relative={relatives[2]} openListDialog={this.props.openListDialog} openAddDialog={this.props.openAddDialog} swapMode={this.props.swapMode} selectedSwaps={this.props.selectedSwaps} swapCheckboxChange={this.props.swapCheckboxChange} />
				</Grid>
			</div>
		);
	}
}

export default Detail;
