import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import logo from './logo.png';
import './Error404.scss';

class Error404 extends Component {
	render() {
		return (
			<Container className="Error404">
				<img src={logo} className="logo" alt="Balcarras Archery" />
				<Typography variant="h5">Page not found</Typography>
			</Container>
		);
	}
}

export default Error404;
