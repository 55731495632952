import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton } from '@material-ui/core';
import { SwapVert, MoreVert, CompareArrows, Close } from '@material-ui/icons';
import './Heading.scss';

class Heading extends Component {

	static propTypes = {
		openDeleteDialog: PropTypes.func.isRequired,
		openAboutDialog: PropTypes.func.isRequired,
		openInstructionsDialog: PropTypes.func.isRequired,
		swapMode: PropTypes.bool.isRequired,
		swapPossible: PropTypes.bool.isRequired,
		swapArchers: PropTypes.func.isRequired,
		exitSwapMode: PropTypes.func.isRequired,
		enterSwapMode: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null
		};
	}

	handleChange = (event, checked) => {
		this.setState({ auth: checked });
	}

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	}

	handleClose = () => {
		this.setState({ anchorEl: null });
	}

	openDeleteDialog = () => {
		this.props.openDeleteDialog();
		this.handleClose();
	}

	openAboutDialog = () => {
		this.props.openAboutDialog();
		this.handleClose();
	}

	openInstructionsDialog = () => {
		this.props.openInstructionsDialog();
		this.handleClose();
	}

	render() {
		return (
			<div className="Heading">
				<AppBar color="secondary">
					<Toolbar>
						<Typography variant="h6">Scores</Typography>
						<div className="icon-buttons">
							{this.props.swapMode ? (
								<div>
									<IconButton disabled={!this.props.swapPossible} onClick={this.props.swapArchers}>
										<CompareArrows />
									</IconButton>
									<IconButton onClick={this.props.exitSwapMode}>
										<Close />
									</IconButton>
								</div>
							) : (
								<div>
									<IconButton onClick={this.props.enterSwapMode}>
										<SwapVert />
									</IconButton>
									<IconButton onClick={this.handleMenu}>
										<MoreVert />
									</IconButton>
									<Menu
										id="menu-appbar"
										anchorEl={this.state.anchorEl}
										getContentAnchorEl={null}
										anchorOrigin={{vertical: "bottom", horizontal: "right"}}
										transformOrigin={{vertical: "top", horizontal: "right"}}
										open={Boolean(this.state.anchorEl)}
										onClose={this.handleClose}
									>
										<MenuItem onClick={this.openAboutDialog}>About</MenuItem>
										<MenuItem onClick={this.openInstructionsDialog}>Instructions</MenuItem>
										<MenuItem onClick={this.openDeleteDialog}>Delete Data</MenuItem>
									</Menu>
								</div>
							)}
						</div>
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

export default Heading;
