import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import 'typeface-roboto';
import Install from './Install';
import Main from './Main';
import Error404 from './Error404';

// Does the user's browser support the HTML5 history API?
// If the user's browser doesn't support the HTML5 history API then we
// will force full page refreshes on each page change.
const supportsHistory = 'pushState' in window.history;

const App = () => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	const theme = React.useMemo(
		() => createMuiTheme({
			palette: {
				type: prefersDarkMode ? 'dark' : 'light',
				primary: {
					main: prefersDarkMode ? '#fff' : '#3f51b5',
				},
				secondary: {
					main: prefersDarkMode ? '#000' : '#fff',
				},
			},
		}),
		[prefersDarkMode],
	);

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter forceRefresh={!supportsHistory}>
				<div>
					<main>
						<Route
							render={({ location }) => {
								return (
									<Route
										location={location}
										render={() => (
											<Switch>
												<Route exact path="/" component={Install} />
												<Route exact path="/app" component={Main} />
												<Route component={Error404} />
											</Switch>
										)}
									/>
								);
							}}
						/>
					</main>
				</div>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;