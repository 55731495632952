import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import logo from './logo.png';
import './Install.scss';

class Install extends Component {
	render() {
		return (
			<Container className="Install">
				<img src={logo} className="logo" alt="Balcarras Archery" />
				<Typography variant="h5">Balcarras Archery</Typography>
				<Typography variant="h6">Install to home screen</Typography>
			</Container>
		);
	}
}

export default Install;
