import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

class AddArcher extends Component {

	static propTypes = {
		open: PropTypes.bool.isRequired,
		closeAddDialog: PropTypes.func.isRequired,
		addArcher: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {name: null};
	}

	handleChange = (event) => {
		this.setState({name: event.target.value});
	}

	handleSubmit = (event) => {
		if (this.state.name !== null) {
			this.props.addArcher(this.state.name);
		}
		
		event.preventDefault();
	}

	render() {
		return (
			<div>
				<Dialog open={this.props.open} onClose={this.props.closeAddDialog}>
					<DialogTitle>Add Archer</DialogTitle>
					<form onSubmit={this.handleSubmit}>
						<DialogContent>
							<TextField color="inherit" variant="outlined" margin="dense" label="Name" type="text" fullWidth onChange={this.handleChange} />
						</DialogContent>
						<DialogActions>
							<Button onClick={this.props.closeAddDialog} color="primary">
								Cancel
							</Button>
							<Button onClick={this.props.closeAddDialog} color="primary" type="submit">
								Add
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</div>
		);
	}
}

export default AddArcher;
