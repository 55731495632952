import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import packageJson from '../package.json';

class About extends Component {
	
	static propTypes = {
		open: PropTypes.bool.isRequired,
		closeAboutDialog: PropTypes.func.isRequired
	};

	render() {
		return (
			<div>
				<Dialog open={this.props.open} onClose={this.props.closeAboutDialog}>
					<DialogTitle>Balcarras Archery</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Version {packageJson.version}
						</DialogContentText>
						<DialogContentText>
							Created by Bilaal Rashid
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.closeAboutDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default About;
