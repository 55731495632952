import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, List, ListItem, ListItemText, DialogContent } from '@material-ui/core';

class ScoresList extends Component {

	static propTypes = {
		id: PropTypes.number.isRequired,
		archers: PropTypes.array.isRequired,
		open: PropTypes.bool.isRequired,
		closeListDialog: PropTypes.func.isRequired,
		openScoreDialog: PropTypes.func.isRequired
	};

	render() {
		const archer = this.props.archers[this.props.id] || {name: null, scores: null};
		const name = archer.name || "Name";
		const scores = archer.scores || [];

		return (
			<div>
				<Dialog open={this.props.open} onClose={this.props.closeListDialog}>
					<DialogTitle>{name}</DialogTitle>
					<DialogContent>
						<Button variant="contained" color="primary" onClick={() => this.props.openScoreDialog()}>
							Add Score
						</Button>
						<List>
							{scores.slice(0).reverse().map((score, index) => (
								<ListItem button key={index} onClick={() => this.props.openScoreDialog(true, (scores.length - index - 1), score)}>
									<ListItemText primary={(score !== null) ? score : 0} />
								</ListItem>
							))}
						</List>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

export default ScoresList;
