import React, { Component } from 'react';
import Detail from './Detail';
import Heading from './Heading';
import AddArcher from './AddArcher';
import DeleteData from './DeleteData';
import AddScore from './AddScore';
import ScoresList from './ScoresList';
import About from './About';
import Instructions from './Instructions';
import './Main.scss';

class Main extends Component {

	constructor(props) {
		super(props);
		const defaultArchers = "[null, null, null, null, null, null, null, null, null, null, null, null]";
		const archers = localStorage.getItem("archers") || defaultArchers;
		const jsonArchers = JSON.parse(archers);
		this.state = {
			openAddDialog: false,
			openDeleteDialog: false,
			openScoreDialog: false,
			openListDialog: false,
			openAboutDialog: false,
			openInstructionsDialog: false,
			scoreID: 0,
			addIndex: null,
			editScore: false,
			editIndex: null,
			previousScore: null,
			swapMode: false,
			selectedSwaps: [],
			archers: jsonArchers
		};
	}

	openAddDialog = (index = null) => {
		this.setState({openAddDialog: true, addIndex: index});
	}

	closeAddDialog = () => {
		this.setState({openAddDialog: false});
	}

	openDeleteDialog = () => {
		this.setState({openDeleteDialog: true});
	}

	closeDeleteDialog = () => {
		this.setState({openDeleteDialog: false});
	}

	openScoreDialog = (edit = false, index = null, score = null) => {
		this.setState({openScoreDialog: true, editScore: edit, editIndex: index, previousScore: score});
	}

	closeScoreDialog = () => {
		this.setState({openScoreDialog: false});
	}

	openListDialog = (id) => {
		this.setState({openListDialog: true, scoreID: id});
	}

	closeListDialog = () => {
		this.setState({openListDialog: false});
	}

	openAboutDialog = () => {
		this.setState({openAboutDialog: true});
	}

	closeAboutDialog = () => {
		this.setState({openAboutDialog: false});
	}

	openInstructionsDialog = () => {
		this.setState({openInstructionsDialog: true});
	}

	closeInstructionsDialog = () => {
		this.setState({openInstructionsDialog: false});
	}

	enterSwapMode = () => {
		this.setState({swapMode: true});
	}

	exitSwapMode = () => {
		this.setState({swapMode: false});
	}

	swapCheckboxChange = (index, event) => {
		const isSelected = event.target.checked;

		if (isSelected) {
			if (this.state.selectedSwaps.length < 2) {
				this.setState({
					selectedSwaps: [...this.state.selectedSwaps, index]
				})
			}
		} else {
			this.setState({
				selectedSwaps: this.state.selectedSwaps.filter((item) => index !== item)
			})
		}
	}

	addArcher = (newName) => {
		let archers = this.state.archers;
		const newArcher = {
			name: newName,
			scores: null
		};

		let position;

		if (this.state.addIndex === null) {
			position = archers.indexOf(null);
		} else {
			position = this.state.addIndex;
		}

		archers[position] = newArcher;
		this.saveArchers(archers);
	}

	deleteData = () => {
		this.setState({archers: []});
		localStorage.clear();
	}

	addScore = (id, newScore, edit = false) => {
		let archers = this.state.archers;
		let archer = archers[id];
		if (edit) {
			archer.scores[this.state.editIndex] = newScore
		} else {
			if (archer.scores == null) {
				archer.scores = [];
			}
			archer.scores.push(newScore);
		}
		archers[id] = archer;
		this.saveArchers(archers);
	}

	swapArchers = () => {
		let archers = this.state.archers;
		const selectedSwaps = this.state.selectedSwaps;

		if (selectedSwaps.length === 2) {
			const temp = archers[selectedSwaps[0]];
			archers[selectedSwaps[0]] = archers[selectedSwaps[1]];
			archers[selectedSwaps[1]] = temp;

			this.saveArchers(archers);

			this.setState({selectedSwaps: []});
		}
	}

	saveArchers = (archers) => {
		this.setState({archers: archers});
		const stringArchers = JSON.stringify(archers);
		localStorage.setItem("archers", stringArchers);
	}

	render() {
		return (
			<div className="Main">
				<Heading openDeleteDialog={this.openDeleteDialog} openAboutDialog={this.openAboutDialog} openInstructionsDialog={this.openInstructionsDialog} swapMode={this.state.swapMode} enterSwapMode={this.enterSwapMode} exitSwapMode={this.exitSwapMode} swapPossible={(this.state.selectedSwaps.length===2)} swapArchers={this.swapArchers} />
				<div>
					<AddArcher open={this.state.openAddDialog} closeAddDialog={this.closeAddDialog} addArcher={this.addArcher} />
					<DeleteData open={this.state.openDeleteDialog} closeDeleteDialog={this.closeDeleteDialog} deleteData={this.deleteData} />
					<AddScore open={this.state.openScoreDialog} id={this.state.scoreID} closeScoreDialog={this.closeScoreDialog} addScore={this.addScore} editScore={this.state.editScore} editIndex={this.state.editIndex} previousScore={this.state.previousScore} />
					<ScoresList open={this.state.openListDialog} id={this.state.scoreID} archers={this.state.archers} closeListDialog={this.closeListDialog} openScoreDialog={this.openScoreDialog} />
					<About open={this.state.openAboutDialog} closeAboutDialog={this.closeAboutDialog} />
					<Instructions open={this.state.openInstructionsDialog} closeInstructionsDialog={this.closeInstructionsDialog} />
				</div>
				<div className="content-container">
					<div className="content">
						<Detail name="Detail 1" row={1} archers={this.state.archers} openListDialog={this.openListDialog} openAddDialog={this.openAddDialog} swapMode={this.state.swapMode} selectedSwaps={this.state.selectedSwaps} swapCheckboxChange={this.swapCheckboxChange} />
						<Detail name="Detail 2" row={2} archers={this.state.archers} openListDialog={this.openListDialog} openAddDialog={this.openAddDialog} swapMode={this.state.swapMode} selectedSwaps={this.state.selectedSwaps} swapCheckboxChange={this.swapCheckboxChange} />
						<Detail name="Detail 3" row={3} archers={this.state.archers} openListDialog={this.openListDialog} openAddDialog={this.openAddDialog} swapMode={this.state.swapMode} selectedSwaps={this.state.selectedSwaps} swapCheckboxChange={this.swapCheckboxChange} />
						<Detail name="Reserve" row={4} archers={this.state.archers} openListDialog={this.openListDialog} openAddDialog={this.openAddDialog} swapMode={this.state.swapMode} selectedSwaps={this.state.selectedSwaps} swapCheckboxChange={this.swapCheckboxChange} />
					</div>
				</div>
			</div>
		);
	}
}

export default Main;
