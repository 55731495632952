import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

class DeleteData extends Component {

	static propTypes = {
		open: PropTypes.bool.isRequired,
		deleteData: PropTypes.func.isRequired,
		closeDeleteDialog: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	openSnackbar = () => {
		this.setState({open: true});
	}

	closeSnackbar = () => {
		this.setState({open: false});
	}

	delete = (event) => {
		this.props.deleteData();
		this.props.closeDeleteDialog();
		this.openSnackbar();
		event.preventDefault();
	}

	render() {
		return (
			<div>
				<Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={this.state.open} autoHideDuration={6000} onClose={this.closeSnackbar} 
					message={
						<span>Deleted Data!</span>
					} 
					action={[
						<IconButton key="close" aria-label="Close" color="inherit" onClick={this.closeSnackbar}>
							<Close />
						</IconButton>
					]} 
				/>
				<Dialog open={this.props.open} onClose={this.props.closeDeleteDialog}>
					<DialogTitle>Are You Sure?</DialogTitle>
					<DialogContent>
						<DialogContentText>
							This will permanently delete all stored data. Only use this to clear data stored from the previous year. 
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.closeDeleteDialog} color="primary">
							Cancel
						</Button>
						<Button onClick={this.delete} color="primary">
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default DeleteData;
