import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

class AddScore extends Component {

	static propTypes = {
		open: PropTypes.bool.isRequired,
		closeScoreDialog: PropTypes.func.isRequired,
		addScore: PropTypes.func.isRequired,
		id: PropTypes.number.isRequired,
		editScore: PropTypes.bool.isRequired,
		previousScore: PropTypes.string
	};

	constructor(props) {
		super(props);
		this.state = {score: null};
	}

	handleChange = (event) => {
		this.setState({score: event.target.value});
	}

	handleSubmit = (event) => {
		if (Number.isInteger(Number(this.state.score)) && this.state.score !== null) {
			this.props.addScore(this.props.id, this.state.score, this.props.editScore);
		}
		
		event.preventDefault();
	}

	render() {
		return (
			<div>
				<Dialog open={this.props.open} onClose={this.props.closeScoreDialog}>
					<DialogTitle>{this.props.editScore ? 'Edit' : 'Add'} Score</DialogTitle>
					<form onSubmit={this.handleSubmit}>
						<DialogContent>
							<TextField fullWidth variant="outlined" margin="dense" label="Score" type="number" onChange={this.handleChange} defaultValue={this.props.previousScore || undefined} />
						</DialogContent>
						<DialogActions>
							<Button onClick={this.props.closeScoreDialog} color="primary">
								Cancel
							</Button>
							<Button onClick={this.props.closeScoreDialog} color="primary" type="submit">
								{this.props.editScore ? 'Edit' : 'Add'}
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</div>
		);
	}
}

export default AddScore;
