import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Card, CardActionArea, CardContent, Checkbox } from '@material-ui/core';
import './Archer.scss';

class Archer extends Component {

	static propTypes = {
		openAddDialog: PropTypes.func.isRequired,
		openListDialog: PropTypes.func.isRequired,
		swapMode: PropTypes.bool.isRequired,
		id: PropTypes.number,
		index: PropTypes.number.isRequired,
		selectedSwaps: PropTypes.array.isRequired,
		swapCheckboxChange: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		last: PropTypes.number.isRequired,
		average: PropTypes.number.isRequired,
		relative: PropTypes.number.isRequired
	};

	handleClick = () => {
		if (!this.props.swapMode) {
			if (this.props.id == null) {
				this.props.openAddDialog(this.props.index);
			} else {
				this.props.openListDialog(this.props.id)
			}
		}
	}

	render() {
		let difference = "same";

		if (this.props.relative === -1) {
			difference = "bad";
		} else if (this.props.relative === 0) {
			difference = "same";
		} else if (this.props.relative === 1) {
			difference = "good";
		}

		return (
			<div className="Archer">
				<Card className="card" onClick={this.handleClick}>
					<CardActionArea>
						<CardContent>
							<Typography noWrap variant="subtitle1" color={(this.props.id!==null) ? "initial" : "textSecondary"}>{this.props.name}</Typography>
							{this.props.swapMode ? (
								<div>
									<Checkbox indeterminate checked={this.props.selectedSwaps.indexOf(this.props.index)>=0} onChange={(event) => this.props.swapCheckboxChange(this.props.index, event)} />
								</div>
							) : (
								<div>
									<div className="clear">
										<Typography variant="caption" display="inline" color={(this.props.id!==null) ? "initial" : "textSecondary"} className="scores-title">Last</Typography>
										<Typography variant="caption" display="inline" color={(this.props.id!==null) ? "initial" : "textSecondary"} className="scores-result">{this.props.last}</Typography>
									</div>
									<div className="clear">
										<Typography paragraph variant="caption" display="inline" color={(this.props.id!==null) ? "initial" : "textSecondary"} className="scores-title">Average</Typography>
										<Typography paragraph variant="caption" display="inline" color={(this.props.id!==null) ? "initial" : "textSecondary"} className={difference + " scores-result"}>{this.props.average}</Typography>
									</div>
								</div>
							)}
						</CardContent>
					</CardActionArea>
				</Card>
			</div>
		);
	}
}

export default Archer;
